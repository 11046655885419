<template>
  <div v-if="show">
    <div class="navigation-panel">
      <div class="navigation-header">
        <img class="book-cover" :src="global.apiUri+detail.cover">
        <p class="book-title">{{ detail.title }}</p>
        <p class="book-arthur" style="margin-top: 10px">作者:{{ detail.author }}</p>
        <div class="navigation-navigation">
          <span class="book-content-title" :class="nav!=1?'select-box':''" @click="nav=1">目录</span>
          <span class="book-bookmark-title" :class="nav!=2?'select-box':''" @click="nav=2">菜单</span>
        </div>
      </div>
      <div class="navigation-body-parent" v-if="nav==1">
        <div class="navigation-body">
          <div class="book-content-container">
            <ul class="book-content">
              <li class="book-content-list" v-for="(item,index) in navigation" :key="index">
                <a style="cursor: pointer" :style="{color:(defaultUrl==item.href?'#409EFF':'')}"
                   @click="openUrl(item.href)" class="book-content-name">
                  {{ item.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="navigation-body-parent" v-else>
        <div class="navigation-body">
          <div class="book-content-container">
            <ul class="book-content">
              <li class="book-content-list" style="margin-top: 10px">
                <div style="display: flex;flex-direction: row;margin-top: 10px;align-items: center">
                  <div>字体大小</div>
                  <el-input type="number" size="mini" style="width: 100px;margin-left: 5px" placeholder="请输入字体大小"
                            v-model="fontSize"
                            class="input-with-select">
                  </el-input>
                </div>
                <el-progress :percentage="Number(defaultFontSize)"></el-progress>

              </li>

              <li class="book-content-list" style="margin-top: 20px">
                <div style="display: flex;flex-direction: row;margin-top: 10px;align-items: center">
                  <div>主题设置</div>
                </div>
                <div
                    style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;width: 100%;margin-top: 10px">
                  <div v-for="(item,index) in themeList" :key="index" @click="setTheme(index)"
                       style="width: 20px;height: 20px;border-radius: 50%;margin-right: 10px;border: 1px solid #aaaaaa;cursor: pointer"
                       :style="{backgroundColor: item.style.body.background}"></div>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="progress-panel-container" style=" ">
      <div class="progress-panel">
        <el-slider
            v-model="progress"
            @change="onProgressChange"
            :step="10" style="width: 350px;margin-left: 20px">
        </el-slider>
        <div style="width: 100%;text-align: center;margin-top: 0px">阅读进度</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bookNav',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fontSizeList: Array,
    defaultFontSize: Number,
    themeList: Array,
    detail: {},
    defaultUrl:{
      type: String,
      default: ''
    },
    navigation: Array,
    defaultTheme: Number,
    defaultProgress: Number,
    bookAvailable: Boolean,
  },
  data() {
    return {
      nav: 1,
      fontSize: this.defaultFontSize,
      settingShow: false,
      showTag: null,
      progress: this.defaultProgress
    }
  },
  watch: {
    fontSize() {
      this.setFontSize(this.fontSize);
    },
    defaultProgress() {
     this.progress=this.defaultProgress;
    }
  },
  methods: {
    // 拖动进度条时触发事件 使用@input来监听 此处用v-model代替
    onProgressInput(progress) {
      this.progress = progress
      this.$refs.progress.style.backgroundSize = `${this.progress}% 100%`
    },
    // 进度条松开后触发事件，根据进度条数值跳转到指定位置 使用@change来监听
    onProgressChange(progress) {
      this.$emit('onProgressChange', progress)
    },
    setTheme(index) {
      this.$emit('setTheme', index)
    },
    openUrl(url) {
      this.$emit('setHref', url)
    },
    showSetting(tag) {
      if (this.showTag === tag) {
        this.showTag = null
        this.hideSetting()
      } else {
        this.showTag = tag
        this.settingShow = true
      }
    },
    hideSetting() {
      this.settingShow = false
    },
    setFontSize(fontSize) {
      this.$emit('setFontSize', fontSize)
    }
  }
}
</script>
<style scoped>
.progress-panel-container {
  width: 412px;
  height: 80px;
  position: absolute;
  top: calc(100vh - 80px);
  left: calc(50vw - 206px);
  z-index: 15;
  transition: transform 0.5s ease;
}

.action-dialog-container, .add-dialog-container, .backup-page-container, .delete-dialog-container, .download-desk-container, .edit-dialog-container, .loading-dialog, .setting-dialog-container, .token-dialog-container, .new-version, .sort-dialog-container, .popup-menu-box, .loading-page-cover, .loading-page-cover, .navigation-panel, .book-operation-panel, .progress-panel, .setting-panel-parent {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 7px rgb(0 0 0 / 20%);
}

.progress-panel {
  width: 412px !important;
  height: 80px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  left: calc(50% - 206px);
  z-index: 10;
  animation: fade-up 0.1s ease-in-out 0s 1;
  padding: 10px;
  box-sizing: border-box;
}

.book-cover {
  float: left;
  width: 91px;
  height: 118px;
  position: relative;
  left: 12px;
  top: 14px;
}

.select-box {
  opacity: 0.5 !important;
}

.navigation-header {
  position: fixed;
  width: 299px;
  height: 173px;
  opacity: 1;
  z-index: 10;
}

.book-title {
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  opacity: 1;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  position: absolute;
  left: 30px;
  top: 20px;
  position: relative;
}

.book-arthur {
  height: 30px;
  font-size: 15px;
  line-height: 15px;
  opacity: 1;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  position: absolute;
  left: 30px;
  top: 10px;
  position: relative;
}

.reading-duration {
  position: absolute;
  left: 120px;
  top: 60px;
  font-size: 14px;
  line-height: 22px;
}

.book-content-title {
  line-height: 27px;
  opacity: 1;
  cursor: pointer;
}

.book-bookmark-title {
  line-height: 27px;
  opacity: 1;
  cursor: pointer;
}

.navigation-body-parent {
  position: absolute;
  top: 173px;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 173px);
}

.navigation-body {
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  z-index: 0;
  overflow-x: hidden;
}


.navigation-navigation {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.navigation-panel {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 299px;
  height: 100%;
  opacity: 1;
  z-index: 10;
  animation: fade-left 0.2s ease-in-out 0s 1;
}


.nav-lock-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  opacity: 0.3;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
}

body {
  -webkit-tap-highlight-color: transparent;
  background: rgba(255, 255, 255, 1);
  color: rgba(75, 75, 75, 1);
}

.book-content-name {
  color: rgba(75, 75, 75, 1);
}

input::-webkit-input-placeholder,
input::-moz-placeholder,
input::-ms-input-placeholder,
textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea::-ms-input-placeholder {
  color: rgba(75, 75, 75, 0.8);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(75, 75, 75, 0.5);
}

.navigation-panel {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}

.navigation-header {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.18);
}

.navigation-header {
  background: rgba(255, 255, 255, 1);
}

.book-content-name {
  border-bottom: 1px solid rgba(75, 75, 75, 0.1);
}


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
iframe,
p,
a,
img,
strong,
b,
u,
i,
ol,
ul,
li,
form,
canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  width: 100%;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
}

li {
  list-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  overflow-x: hidden !important;
  /* font-weight: 500; */
}

option::-ms-expand {
  display: none;
}

option {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

/* --背景色字体颜色--*/
option:hover {
  color: #fff;
  background-color: #1e90ff;
}

::-webkit-scrollbar {
  width: 7px;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  transition: all 0.2s;
  border-radius: 0.5rem;
  opacity: 0.5;
}

.book-content-list {
  float: left;
  width: calc(100% - 15px);
  margin-left: 18px;
}

.book-content-name {
  display: block;
  padding: 10px 0px;
  width: 90%;
  font-size: 15px;
  line-height: 18px;
  opacity: 1;
  float: left;
  transition: none;
}
</style>