<template>
  <div class="ebook">
    <!--    <MenuBar-->
    <!--        :show="ifTilteAndMenuShow"-->
    <!--        :fontSizeList="fontSizeList"-->
    <!--        :defaultFontSize="defaultFontSize"-->
    <!--        @setFontSize="setFontSize"-->
    <!--        :themeList="themeList"-->
    <!--        :defaultTheme="defaultTheme"-->
    <!--        @setTheme="setTheme"-->
    <!--        :bookAvailable="bookAvailable"-->
    <!--        @onProgressChange="onProgressChange"-->
    <!--    />-->
    <book-nav
        :show="ifTilteAndMenuShow"
        :fontSizeList="fontSizeList"
        :defaultFontSize="defaultFontSize"
        @setFontSize="setFontSize"
        :themeList="themeList"
        :defaultTheme="defaultTheme"
        :defaultProgress="defaultProgress"
        @setTheme="setTheme"
        @setHref="setHref"
        :bookAvailable="bookAvailable"
        :navigation="navigation"
        :detail="detail"
        :defaultUrl="defaultUrl"
        @onProgressChange="onProgressChange"
    ></book-nav>
    <div class="read-wrapper">
      <div id="read" class="html-viewer-page"></div>
      <i class="el-icon-arrow-left" style="font-size: 40px; position: fixed;left: 0;top: 50%;cursor: pointer"
         @click="prevPage"></i>

      <i class="el-icon-menu" style="font-size: 30px; position: fixed;right: 10px;top: 10px;cursor: pointer"
         @click="toggleTitleAndMenuShow"></i>
      <i class="el-icon-arrow-right" style="font-size: 40px;position: fixed;right: 0;top: 50%;cursor: pointer"
         @click="nextPage"></i>
    </div>

    <el-image-viewer v-if="imgViewerVisible" :on-close="closeImgViewer" :url-list="imgList"/>
  </div>
</template>
<script>
import Epub from 'epubjs'
import TitleBar from '@/components/TitleBar'
import MenuBar from '@/components/MenuBar'
import BookNav from "../../components/ebook/bookNav";
// import {TxtRender}  from '@/lib/kookit/kookit.umd'

const DOWNLOAD_URL = ''
global.ePub = Epub
export default {
  name: 'reader',
  components: {
    BookNav,
    TitleBar,
    MenuBar,
    DOWNLOAD_URL: '',
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      ifTilteAndMenuShow: false,
      imgViewerVisible: false,
      defaultProgress: 0,
      fontSizeList: [
        {fontSize: 10},
        {fontSize: 12},
        {fontSize: 14},
        {fontSize: 16},
        {fontSize: 18},
        {fontSize: 20},
        {fontSize: 22},
        {fontSize: 24},
        {fontSize: 26},
      ],
      detail: {},
      imgList: [],
      navigation: [],
      defaultFontSize: 16,
      defaultUrl: '',
      themeList: [
        {
          name: 'default',
          style: {
            body: {
              'color': '#000',
              'background': '#fff'
            }
          }
        },
        {
          name: 'eye',
          style: {
            body: {
              'color': '#000',
              'background': '#ceeaba'
            }
          }
        },
        {
          name: 'night',
          style: {
            body: {
              'color': '#fff',
              'background': '#000'
            }
          }
        },
        {
          name: 'gold',
          style: {
            body: {
              'color': '#000',
              'background': 'rgb(241, 236, 226)'
            }
          }
        }
      ],
      defaultTheme: 0,
      bookAvailable: false,
      navInfo: [],
    }
  },
  created() {
    this.bookId = this.$route.query.bookId;
    setTimeout(() => {
      //异步执行列表刷新
      this.getBookInfo();
      this.addBook();
    }, 0)
  },
  beforeMount() {
    addEventListener('message', function (evt) {
      if (evt.data.type !== 'childStatus') {
        return
      }
      console.log(evt.data.data);
    })
  },
  mounted() {
    let _this = this;
    _this.$nextTick(() => {
      setTimeout(function () {
        let doc = _this.getIframeDoc();
        doc.addEventListener("click", _this.showImage);
      }, 1000)
    })
  },
  methods: {
    closeImgViewer() {
      this.imgViewerVisible = false;
    },
    showImage() {
      if (event.target.src) {
        console.log(event.target.src);
        this.imgList[0] = event.target.src;
        this.imgViewerVisible = true;
      }
    },
    getIframeDoc() {
      let pageArea = document.getElementById("read");
      if (!pageArea) return null;
      let iframe = pageArea.getElementsByTagName("iframe")[0];
      let doc = iframe.contentDocument;
      if (!doc) {
        return null;
      }
      return doc;

    },
    getBookInfo() {
      let _this = this;
      _this.axios.get('/ebook/book_info?bid=' + _this.bookId)
          .then(function (res) {
            if (res.code == 200) {
              _this.detail = res.data;
              _this.DOWNLOAD_URL = res.data.epub;
              _this.showEpub(res.data.progress);

              setTimeout(() => {
                _this.navigation = _this.book.navigation.toc;
                console.log(_this.book.navigation.toc);
                _this.getProgress();
                _this.onProgressChange(res.data.progress);
              }, 1000)

            } else {
              _this.$message.error(res.msg);
            }
          });
    },
    addBook() {
      let _this = this;
      _this.axios.get('/user/add_browse?bid=' + _this.bookId);
    },
    onProgressChange(progress) {
      let _this=this;
      const percentage = progress / 100
      const location = percentage > 0 ? _this.locations.cfiFromPercentage(percentage) : 0
      _this.rendition.display(location);
      _this.$nextTick(() => {
        setTimeout(function () {
          let doc = _this.getIframeDoc();
          doc.addEventListener("click", _this.showImage);
        }, 1000)
      })
    },
    setTheme(index) {
      this.themes.select(this.themeList[index].name)
      this.defaultTheme = index
    },
    setHref(url) {
      let _this=this;
      _this.book.rendition.display(url)
      _this.defaultUrl = url;
      console.log(_this.book)
      _this.getProgress();
      _this.$nextTick(() => {
        setTimeout(function () {
          let doc = _this.getIframeDoc();
          doc.addEventListener("click", _this.showImage);
        }, 1000)
      })
    },
    registerTheme() {
      this.themeList.forEach(theme => {
        this.themes.register(theme.name, theme.style)
      })
    },
    setFontSize(fontSize) {
      let _this=this;
      _this.defaultFontSize = Number(fontSize)
      if (_this.themes) {
        _this.themes.fontSize(this.defaultFontSize + 'px')
      }
      _this.$nextTick(() => {
        setTimeout(function () {
          let doc = _this.getIframeDoc();
          doc.addEventListener("click", _this.showImage);
        }, 1000)
      })
    },
    toggleTitleAndMenuShow() {
      this.ifTilteAndMenuShow = !this.ifTilteAndMenuShow
    },
    // 电子书的解释和渲染
    showEpub(progress) {
      // 生成Book
      this.book = new Epub(this.DOWNLOAD_URL)
      this.navInfo = this.book.navigation;
      // 生成Rendition, 通过Book.renderTo
      this.rendition = this.book.renderTo('read', {
        // width: window.innerWidth,
        // height: window.innerHeight,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      })
      // 通过Rendtion.display 渲染电子书
      this.rendition.display();
      // 获取Theme对象
      this.themes = this.rendition.themes
      // 设置默认字体
      this.setFontSize(this.defaultFontSize)
      // this.themes.register(name, styles) 注册字体
      // this.themes.select(name) 选择字体
      this.registerTheme()
      this.setTheme(this.defaultTheme)
      // 获取Locations对象 Locations 默认不生成
      console.log(this.book)
      this.locations = this.book.locations;

      this.book.ready.then(() => {
        return this.book.locations.generate();
      }).then(() => {
        this.locations = this.book.locations
        this.bookAvailable = true;
      })

    },

    getProgress() {
      var currentLocation = this.rendition.currentLocation();
      this.defaultProgress = Math.floor(((this.locations.percentageFromCfi(currentLocation.start.cfi)).toFixed(5)) * 10000) / 100;
      console.log(this.defaultProgress);
    },
    nextPage() {
      let _this = this;
      if (_this.rendition) {
        _this.rendition.next();
        _this.getProgress();
        var currentLocation = _this.rendition.currentLocation();
        let progress = Math.floor(((_this.locations.percentageFromCfi(currentLocation.start.cfi)).toFixed(5)) * 10000) / 100;
        _this.$nextTick(() => {
          setTimeout(function () {
            let doc = _this.getIframeDoc();
            doc.addEventListener("click", _this.showImage);
          }, 1000)
        })
        _this.axios.post('/user/update_browse', {progress: progress, bookId: _this.bookId});

      }
    },


    prevPage() {
      let _this = this;
      if (this.rendition) {
        this.getProgress();
        this.rendition.prev();
        var currentLocation = _this.rendition.currentLocation();
        let progress = Math.floor(((_this.locations.percentageFromCfi(currentLocation.start.cfi)).toFixed(5)) * 10000) / 100;
        _this.$nextTick(() => {
          setTimeout(function () {
            let doc = _this.getIframeDoc();
            doc.addEventListener("click", _this.showImage);
          }, 1000)
        })
        _this.axios.post('/user/update_browse', {progress: progress, bookId: _this.bookId});
      }
    }
  },
}
</script>
<style lang="stylus" scoped>
.right {
  display flex;
  flex-direction column;
  align-items: flex-end;
  justify-content center
}

.left {
  display flex;
  flex-direction column;
  align-items: flex-start;
  justify-content center
}

.ebook {
  position: relative
  overflow: hidden

}
</style>